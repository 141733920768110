<template>
  <header id="topnav">
    <!-- Topbar Start -->
    <div class="navbar-custom">
      <div class="container-fluid">
        <ul class="list-unstyled topnav-menu float-right mb-0">
          <li class="dropdown notification-list">
            <!-- Mobile menu toggle-->
            <a
              :class="toggleClassList"
              @click="toggleNav"
            >
              <div class="lines">
                <span />
                <span />
                <span />
              </div>
            </a>
            <!-- End mobile menu toggle-->
          </li>
          <li
            v-if="hideHeaderUrls()"
            class="dropdown notification-list"
          >
            <a
              class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <img
                src="https://app.smartmawaid.com/assets/images/users/avatar.png"
                alt="user-image"
                class="rounded-circle"
              >
              <span class="pro-user-name ml-1">{{ user ? user.name : '' }} <i class="mdi mdi-chevron-down" />

              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-right profile-dropdown ">
              <!-- item-->
              <div class="dropdown-header noti-title">
                <h5 class="text-overflow m-0">
                  {{ user ? user.company_name : '' }}
                </h5>
                <h6 class="text-overflow">
                  ( {{ user ? user.designation : '' }} )
                </h6>
                <p
                  class="text-decoration-underline tutorial mt-5"
                  @click="restartOnboarding"
                >
                  Take Tutorial
                </p>
              </div>

              <div class="dropdown-divider" />

              <!-- item-->
              <a
                class="dropdown-item notify-item"
                @click="signOut"
              >
                <i class="fe-log-out" />
                <span>Logout</span>
              </a>
            </div>
          </li>
        </ul>

        <!-- LOGO -->
        <div class="logo-box">
          <a
            href="https://app.smartmawaid.com/recipe"
            class="logo text-center logo-dark"
          >
            <span class="logo-lg">
              <img
                src="https://app.smartmawaid.com/assets/images/logo.png"
                alt=""
              >
              <!-- <span class="logo-lg-text-dark">Smart</span> -->
            </span>
            <span class="logo-sm">
              <!-- <span class="logo-lg-text-dark">A</span> -->
              <img
                src="https://app.smartmawaid.com/assets/images/logo.png"
                alt=""
                style="width: 150px;"
              >
            </span>
          </a>
        </div>
        <div class="clearfix" />
      </div>
    </div>
    <!-- end Topbar -->
    <div
      v-if="hideHeaderUrls()"
      class="topbar-menu"
    >
      <div class="container-fluid">
        <div
          id="navigation"
          :style="navToggle?'display:block':'display:none'"
        >
          <!-- Navigation Menu-->
          <ul class="navigation-menu">
            <li
              v-for="item in links"
              :class="[...topMenuClass,item.isSubMenuOpen ? 'open':'']"
              @click="toggleSubMenu(item)"
            >
              <router-link
                :to="item.subMenu && item.subMenu.length > 0 ? '#': item.link"
                :target="item.target"
              >
                <i :class="item.icon" />{{ item.title }}
              </router-link>
              <ul
                v-if="item.subMenu && item.subMenu.length > 0"
                :class="[...subMenuClass,item.isSubMenuOpen ? 'open':'']"
              >
                <li v-for="menu of item.subMenu">
                  <router-link :to="menu.link">
                    {{ menu.title }}
                  </router-link>
                </li>
              </ul>
            </li>
            <li :class="[...topMenuClass]">
              <a
                href="https://weconnectdirect.uservoice.com/"
                target="_blank"
              >
                <i class="mdi mdi-help-circle" />Support
              </a>
            </li>
            <li class="has-submenu translate-element">
              <div id="google_translate_element" />
            </li>
            <!--            <li
              :class="demoSwitchClassList"
            >
              <v-switch
                v-model="demoMode"
                color="white"
                dense
                @mousedown="demoModeConfirmDialog = true"
              >
                <template #label>
                  <span class="mt-1 text-white font-14"> Demo Mode : {{ demoMode ? 'Enabled' : 'Disabled' }}</span>
                </template>
              </v-switch>
            </li>-->
          </ul>
          <!-- End navigation menu -->
          <div class="clearfix" />
        </div>
        <!-- end #navigation -->
      </div>
      <!-- end container -->
    </div>
    <!-- end navbar-custom -->
    <v-dialog
      v-model="demoModeConfirmDialog"
      max-width="500px"
      style="z-index:20001;"
    >
      <v-card>
        <v-card-title>
          <v-col>
            <v-row class="font-18">
              Are you sure you want to {{ !demoMode ? 'enable' : 'disable' }} pantry demo mode?
            </v-row>
            <v-row
              class="font-13 font-weight-bold"
            >
              ( Switching {{ !demoMode ? 'to' : 'from' }} demo mode will not change your real data )
            </v-row>
          </v-col>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="demoModeConfirmDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="resetLoader"
            @click="turnOffDemoMode"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="
        snackType === 'success'
          ? 'green'
          : snackType === 'error'
            ? 'red'
            : 'yellow'
      "
      :timeout="1500"
    >
      {{ snackText }}
    </v-snackbar>
  </header>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  components: {},
  data () {
    return {
      signOutLoader: false,
      profileMenu: false,
      demoModeConfirmDialog: false,
      resetLoader: false,
      navToggle: false,
      snackbar: false,
      snackText: '',
      snackType: 'success',
      toggleClassList: ['navbar-toggle', 'nav-link'],
      demoSwitchClassList: ['has-submenu'],
      subMenuClass: ['submenu in'],
      topMenuClass: ['has-submenu'],
      links: [
        { title: 'Dashboard', link: '/dashboard', icon: 'mdi mdi-home', target: '' },
        { title: 'Stock In', link: '/stock-in', icon: 'mdi mdi-package-variant', target: '' },
        // { title: 'Menu Manifest', link: '/menu-manifest', icon: 'mdi mdi-food', target: '' },
        {
          title: 'Stock Estimator',
          link: '#',
          icon: 'mdi mdi-calculator',
          target: '',
          isSubMenuOpen: false,
          subMenu: [
            {
              title: 'Estimate By Recipe',
              link: '/estimate-recipe'
            },
            {
              title: 'Estimate By Menu',
              link: '/estimate-menu'
            }
          ]
        },
        {
          title: 'Stock Out',
          link: '/stock-out',
          icon: 'mdi mdi-package-variant-closed',
          target: '',
          isSubMenuOpen: false,
          subMenu: [
            {
              title: 'Stock Out By Ingredients',
              link: '/stock-out'
            },
            {
              title: 'Stock Out By Menu',
              link: '/stock-out-menu'
            }
          ]

        },
        { title: 'Audit', link: '/audit', icon: 'mdi mdi-file', target: '' },
        { title: 'Pantry Reports', link: '/reports', icon: 'mdi mdi-file-chart', target: '' },
        { title: 'User Guide', link: '/user-guide', icon: 'mdi mdi-book-open', target: '' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/getCurrentUser',
      demoMode: 'config/getDemoMode'
    })
  },
  mounted () {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.sideBarClass = window.matchMedia('(max-width: 990px)').matches
        ? this.demoSwitchClassList = ['has-submenu'] : this.demoSwitchClassList = ['has-submenu', 'float-right']
    },
    changeValue () {
      this.demoModeConfirmDialog = true
    },
    async restartOnboarding () {
      await this.$store.dispatch('config/restartOnboarding', this.user.company_name_slug + '_' + this.user.company_id)
    },
    cancelDemoMode () {
      this.demoModeConfirmDialog = false
    },
    async turnOffDemoMode () {
      this.resetLoader = true
      const status = await this.$store.dispatch('config/resetData', {
        company_name: this.user.company_name_slug,
        company_id: this.user.company_id,
        demoMode: !this.demoMode
      })
      this.demoModeConfirmDialog = false
      this.resetLoader = false
      if (status) {
        this.showSnackNotification('Data reset successfully', 'success')
        this.$router.go(0)
      } else {
        this.showSnackNotification('Error while resetting data', 'error')
      }
    },
    hideHeaderUrls () {
      return !(this.$route.path === '/login' || this.$route.path === '/logout' || this.$route.path === '/sso/login')
    },
    showSnackNotification (text, type) {
      this.snackText = text
      this.snackType = type
      this.snackbar = true
    },
    toggleNav () {
      this.navToggle = !this.navToggle
      if (this.toggleClassList.includes('open')) {
        this.toggleClassList.pop()
      } else {
        this.toggleClassList.push('open')
      }
    },
    toggleSubMenu (item) {
      if (this.topMenuClass.includes('open')) {
        this.topMenuClass.pop()
      } else {
        this.topMenuClass.push('open')
      }
      item.isSubMenuOpen = !item.isSubMenuOpen
    },
    async signOut () {
      this.signOutLoader = true
      try {
        await this.$store.dispatch('auth/signUserOut')
        this.signOutLoader = false
        await this.$router.replace('/logout')
      } catch (e) {
        this.signOutLoader = false
      }
    }
  }
}
</script>
<style scoped>
.pro-user-name {
  color: #8c9396;
}

.navigation-menu {
  text-align: left
}

.navigation-menu > li > a {
  color: #f3e4c0;
  display: block;
  font-size: 14.4px;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  line-height: 20px;
  padding: 20px 17px;
  position: relative;

}

.navigation-menu > li > a > i {
  display: inline-block;
  font-size: 16px;
  margin-bottom: 0px;
  margin-right: 8px;
}

#topnav {
  background-color: #cb8037
}

.navbar-custom {
  background-color: #ffffff;;
}

.logo-box {
  background: none !important;
}

.tutorial {
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .logo-box {
    display: block !important;
    width: 120px !important;
  }

  .logo-lg {
    width: 130px !important;
  }

  .logo-lg > img {
    width: 130px !important;
  }
}

.active {
  color: #ffffff !important;
  font-weight: 600;
  text-decoration: underline;
}

.translate-element {
  margin-left: 10px;
}

@media only screen and (max-width: 1290px) {
  .translate-element {
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .navigation-menu > li .submenu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    padding: 10px 0;
    list-style: none;
    min-width: 200px;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    margin-top: 10px;
    border-radius: 4px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    background-color: #f3e4c0;
    -webkit-box-shadow: rgb(0 0 0 / 15%) 0px 0px 40px 0px;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 40px 0px;
  }
}
</style>
